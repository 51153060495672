<template>
  <div class="h-100" style="position: relative;">
    <b-button
      v-if="enableButtonWhenNot(['PAID', 'SCHEDULED', 'DISPUTED', 'CANCELED', 'BONUS', 'BALANCE_CREDITED']) && data.id"
      :disabled="block_ui"
      data-test="modal-send-invoice"
      variant="success"
      block
      @click="$emit('openModalsendInvoice')"
    >
      {{ modal.send_invoice_button }}
    </b-button>

    <b-button
      v-if="enableButtonWhenNot(['PAID', 'MANUALLY_PAID', 'CANCELED', 'NESTED_NEGOTIATED']) && data.barcode_number"
      :href="'https://fatura.kovi.com.br/' + data.id"
      target="_blank"
      data-test="modal-transaction__btn-print"
      variant="outline-primary "
      block
    >
      Imprimir fatura
    </b-button>

    <b-button
      v-if="
        enableButtonWhenNot(['PAID', 'MANUALLY_PAID', 'CANCELED', 'BALANCE_CREDITED', 'NESTED_NEGOTIATED']) &&
          data.barcode_number !== null &&
          data.id &&
          $moment().startOf('day').diff(data.expired_at, 'days') > 0
      "
      :disabled="block_ui"
      variant="warning"
      data-test="modal-transaction__btn-reissue"
      block
      @click="$emit('reIssueBoleto')"
    >
      {{ modal.reissue_button }}
    </b-button>

    <b-button
      v-if="enableCancelButton"
      :disabled="block_ui"
      variant="danger"
      data-test="modal-transaction__btn-cancel"
      block
      @click="$emit('cancelTransaction')"
    >
      {{ modal.cancel_button }}
    </b-button>

    <b-button
      v-if="
        data.status === 'PAID' &&
          data.payment_method_type !== 'credit_card' &&
          findGroups('RULE:TRANSACTIONS:REFUNDED')"
      variant="warning"
      data-test="modal-transaction__btn-save"
      block
      @click="$emit('refundPayment')"
    >
      {{ modal.refundButton }}
    </b-button>

    <b-button
      v-if="
        enableButtonWhenNot(['PAID', 'MANUALLY_PAID', 'CANCELED', 'SCHEDULED', 'BALANCE_CREDITED', 'NESTED_NEGOTIATED', 'PRE_PAYMENT_RESERVE']) &&
          data.id &&
          data.balance !== 0 &&
          debitCreditManualConfirmation &&
          findGroups('RULE:TRANSACTIONS:BALANCE')
      "
      v-b-tooltip.hover
      variant="success"
      :title="$t('payments.buttons.convertToCreditsTitle')"
      block
      @click="$emit('openModalDebitBalance')"
    >
      {{ $t('payments.buttons.debitCredit' ) }}
    </b-button>

    <b-button
      v-if="!enableButtonWhenNot(['PAID']) && (isFirstPaymentExchangeAndHasPermission || isDefaultKindAndHasPermission)"
      v-b-tooltip.hover
      variant="success"
      :title="$t('payments.buttons.debitCreditTitle')"
      block
      @click="openConvertToCreditsModal"
    >
      {{ $t('payments.buttons.convertToCredits') }}
    </b-button>

    <b-button
      v-if="
        !enableButtonWhenNot(['PAID']) && (
          isPrePaymentAndCreditCardAndHasPermission
          || isPrePaymentAndNotCreditCardAndHasPermission
          || isPrePaymentReserveAndHasPermission
          || isPrePaymentInstallmentAndHasPermission)"
      v-b-tooltip.hover
      variant="success"
      :title="$t('payments.buttons.debitCreditTitle')"
      block
      @click="prePaymentInCredits"
    >
      {{ $t('payments.buttons.convertPrePaymentInCredit') }}
    </b-button>

    <b-button
      v-if="
        !enableButtonWhenNot(['PAID']) &&
          ['PRE_PAYMENT_RTO'].includes(data.kind) &&
          findGroups('RULE:TRANSACTIONS:CREDIT_PRE_PAYMENT_RTO')"
      v-b-tooltip.hover
      variant="success"
      :title="$t('payments.buttons.debitCreditTitle')"
      block
      @click="prePaymentInCredits"
    >
      {{ $t('payments.buttons.convertPrePaymentRtoInCredit') }}
    </b-button>

    <b-button
      v-if="
        !enableButtonWhenNot(['PENDING', 'REFUNDED', 'DISPUTED']) &&
          (findGroups('RULE:TRANSACTIONS:CREDIT_PRE_PAYMENT') ||
            findGroups('RULE:TRANSACTIONS:CREDIT_PRE_PAYMENT_RTO'))"
      v-b-tooltip.hover
      variant="success"
      :title="$t('payments.buttons.debitCreditTitle')"
      block
      @click="payWithPrePayment"
    >
      {{ $t('payments.buttons.payWithPrePayment') }}
    </b-button>
    <b-button
      v-if="data.status === 'PENDING' &&
        !['PRE_PAYMENT', 'PRE_PAYMENT_RTO', 'PRE_PAYMENT_INSTALLMENT', 'PRE_PAYMENT_PROMOTIONAL'].includes(data.kind) &&
        findGroups('RULE:TRANSACTIONS:BONUS')"
      v-b-tooltip.hover
      variant="success"
      :title="$t('payments.buttons.markAsBonusTitle')"
      block
      @click="$emit('markAsBonus')"
    >
      {{ $t('payments.buttons.markAsBonus') }}
    </b-button>

    <b-button
      v-if="data.status === 'PENDING' &&
        data.holmes_status !== 'PENDING' &&
        data.holmes_status !== 'SUCCESS' &&
        data.gateway === 'stark_bank' &&
        getHourChecked"
      v-b-tooltip.hover
      variant="warning"
      :title="$t('payments.buttons.checkPaymentAttemptTitle')"
      block
      @click="$emit('checkPaymentAttempt')"
    >
      {{ $t('payments.buttons.checkPaymentAttempt') }}
    </b-button>

    <b-button
      v-if="isContestationButtonsAllowed && !isContestationOpen && ['PENDING', 'PAID'].includes(data.status)"
      variant="warning"
      block
      @click="$emit('openModalOpenContestation')"
    >
      {{ $t('payments.buttons.openContestation') }}
    </b-button>

    <b-button
      v-if="isContestationButtonsAllowed && isContestationOpen"
      variant="warning"
      block
      @click="$emit('openModalCloseContestation')"
    >
      {{ $t('payments.buttons.closeContestation') }}
    </b-button>

    <div class="w-100" style="position: absolute; bottom: 0; right:0;">
      <b-button
        data-test="modal-transaction__btn-close"
        class="relative"
        block
        @click="$emit('close')"
      >
        {{ modal.close_button }}
      </b-button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'FooterStatusPending',
  props: {
    data: {
      type: Object,
      required: true,
    },
    modal: {
      type: Object,
      required: true,
    },
    block_ui: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapGetters('user', ['findGroups']),
    hasPendingTransactionOps() {
      const hasItems = (this.data.transaction_operations?.items?.length > 0) ? true : false;

      if(hasItems) {
        const items = this.data.transaction_operations.items
        const hasPending = items.some(item => item.kind === 'CREDIT_APPLYED' && item.status === 'PENDING')
        return (hasPending) ? true : false
      }

      return false
    },
    hasReprovedTransactionOps() {
      const hasItems = (this.data.transaction_operations?.items?.length > 0) ? true : false;

      if(hasItems) {
        const items = this.data.transaction_operations.items
        const hasReproved = items.some(item => item.kind === 'CREDIT_APPLYED' && item.status === 'REPROVED')
        return (hasReproved) ? true : false
      }

      return false
    },
    enableCancelButton() {
      const validation =
        this.data.status !== 'PAID' &&
        this.data.status !== 'MANUALLY_PAID' &&
        this.data.status !== 'CANCELED' &&
        this.data.status !== 'BALANCE_CREDITED' &&
        this.data.status !== 'NESTED_NEGOTIATED' &&
        this.data.kind !== 'NEGOTIATION' &&
        this.data.id;

      return this.checkCancelButtonKind(this.data.kind) && validation && this.$rules.transaction.cancel;
    },
    debitCreditManualConfirmation() {
      if (this.data.manual_confirmation_at !== null) {
        return (
          this.$moment()
            .startOf('day')
            .diff(this.data.manual_confirmation_at, 'days') >= 2
        );
      }
      return true;
    },
    ...mapGetters('user', ['findGroups']),
    getHourChecked() {
      if (this.data.holmes_checked_at) {
        const dateUtc = this.$moment(this.data.holmes_checked_at).utc().format('YYYY-MM-DD HH:mm:ss');
        return this.$moment().utc().diff(dateUtc, 'minutes') > 60
      }
      return true
    },
    isPrePaymentAndCreditCardAndHasPermission() {
      return ['credit_card', 'multi_payment_method'].includes(this.data.payment_method_type) &&
          ['PRE_PAYMENT', 'DESMOB_PRE_PAYMENT'].includes(this.data.kind) && this.findGroups('RULE:TRANSACTIONS:CREDIT_PRE_PAYMENT:CREDIT_CARD')
    },
    isPrePaymentAndNotCreditCardAndHasPermission() {
      return !['credit_card', 'multi_payment_method'].includes(this.data.payment_method_type) &&
          ['PRE_PAYMENT', 'DESMOB_PRE_PAYMENT'].includes(this.data.kind) && this.findGroups('RULE:TRANSACTIONS:CREDIT_PRE_PAYMENT')
    },
    isPrePaymentReserveAndHasPermission() {
      return ['PRE_PAYMENT_RESERVE'].includes(this.data.kind) && this.findGroups('RULE:TRANSACTIONS:CREDIT_PRE_PAYMENT_RESERVE')
    },
    isPrePaymentInstallmentAndHasPermission() {
      return ['PRE_PAYMENT_INSTALLMENT'].includes(this.data.kind) && this.findGroups('RULE:TRANSACTIONS:CREDIT_PRE_PAYMENT_INSTALLMENT')
    },
    isFirstPaymentExchangeAndHasPermission() {
      return ['FIRST_PAYMENT_EXCHANGE'].includes(this.data.kind) && this.findGroups('RULE:TRANSACTIONS:BALANCE:FIRST_PAYMENT_EXCHANGE')
    },
    isDefaultKindAndHasPermission() {
      return ![
        'PRE_PAYMENT',
        'FIRST_PAYMENT_EXCHANGE',
        'PRE_PAYMENT_RTO',
        'PRE_PAYMENT_PROMOTIONAL',
        'DESMOB_PRE_PAYMENT',
        'PRE_PAYMENT_RESERVE',
        'PRE_PAYMENT_INSTALLMENT',
      ].includes(this.data.kind) && this.findGroups('RULE:TRANSACTIONS:BALANCE')
    },
    isContestationButtonsAllowed() {
      return this.findGroups('RULE:TRANSACTIONS:CONTESTATION')
    },
    isContestationOpen() {
      return this.data.contestation && this.data.contestation.status === 'OPEN'
    }
  },
  methods: {
    openReprovedConfirmation() {
      this.$swal({
        type: 'warning',
        titleText: this.$t('payments.creditRequest.modalConvertToCreditsReprovedConfirm'),
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        showCancelButton: true,
        showConfirmButton: true,
      }).then(result => {
        if (result.dismiss) return;

        this.$emit('openModalCreditBalance');

      });
    },
    openConvertToCreditsModal() {
      if(!this.hasPendingTransactionOps && this.hasReprovedTransactionOps) {
        this.openReprovedConfirmation();
        return;
      }

      if(!this.hasPendingTransactionOps && !this.hasReprovedTransactionOps) {
        this.$emit('openModalCreditBalance');
        return;
      }

      const configSwalFail = {
        type: 'warning',
        title: this.$t('payments.creditRequest.modalConvertToCreditsWarning'),
        text: '',
      };

      this.$swal(configSwalFail);
    },
    enableButtonWhenNot (status) {
      return !(status.includes(this.data.status))
    },
    prePaymentInCredits () {
      this.$emit('convertPrePaymentInCredits', this.data)
    },
    payWithPrePayment () {
      this.$emit('payWithPrePayment', this.data)
    },
    checkCancelButtonKind(kind) {
      if (kind === 'COLLISION_COPARTICIPATION_KOVI') return this.findGroups('RULE:TRANSACTIONS:CANCEL:COLLISION_COPARTICIPATION_KOVI')
      if (kind === 'THEFT_COPARTICIPATION_MINIMUN') return this.findGroups('RULE:TRANSACTIONS:CANCEL:THEFT_COPARTICIPATION_MINIMUN')
      if (kind === 'THEFT_COPARTICIPATION_MAXIMUM') return this.findGroups('RULE:TRANSACTIONS:CANCEL:THEFT_COPARTICIPATION_MAXIMUM')
      if (kind === 'BAD_USE_INACTIVE') return this.findGroups('RULE:TRANSACTIONS:CANCEL:BAD_USE_INACTIVE')
      if (kind === 'PRE_PAYMENT_INSTALLMENT') return this.findGroups('RULE:TRANSACTIONS:CANCEL:PRE_PAYMENT_INSTALLMENT')
      return true
    }
  }
};
</script>

<style scoped>
.print_contract {
  border-color: #c8ced3;
  font-weight: bold;
  position: absolute;
  left: 20px;
}
</style>
