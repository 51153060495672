var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "h-100", staticStyle: { position: "relative" } },
    [
      _vm.enableButtonWhenNot([
        "PAID",
        "SCHEDULED",
        "DISPUTED",
        "CANCELED",
        "BONUS",
        "BALANCE_CREDITED",
      ]) && _vm.data.id
        ? _c(
            "b-button",
            {
              attrs: {
                disabled: _vm.block_ui,
                "data-test": "modal-send-invoice",
                variant: "success",
                block: "",
              },
              on: {
                click: function ($event) {
                  return _vm.$emit("openModalsendInvoice")
                },
              },
            },
            [_vm._v(" " + _vm._s(_vm.modal.send_invoice_button) + " ")]
          )
        : _vm._e(),
      _vm.enableButtonWhenNot([
        "PAID",
        "MANUALLY_PAID",
        "CANCELED",
        "NESTED_NEGOTIATED",
      ]) && _vm.data.barcode_number
        ? _c(
            "b-button",
            {
              attrs: {
                href: "https://fatura.kovi.com.br/" + _vm.data.id,
                target: "_blank",
                "data-test": "modal-transaction__btn-print",
                variant: "outline-primary ",
                block: "",
              },
            },
            [_vm._v(" Imprimir fatura ")]
          )
        : _vm._e(),
      _vm.enableButtonWhenNot([
        "PAID",
        "MANUALLY_PAID",
        "CANCELED",
        "BALANCE_CREDITED",
        "NESTED_NEGOTIATED",
      ]) &&
      _vm.data.barcode_number !== null &&
      _vm.data.id &&
      _vm.$moment().startOf("day").diff(_vm.data.expired_at, "days") > 0
        ? _c(
            "b-button",
            {
              attrs: {
                disabled: _vm.block_ui,
                variant: "warning",
                "data-test": "modal-transaction__btn-reissue",
                block: "",
              },
              on: {
                click: function ($event) {
                  return _vm.$emit("reIssueBoleto")
                },
              },
            },
            [_vm._v(" " + _vm._s(_vm.modal.reissue_button) + " ")]
          )
        : _vm._e(),
      _vm.enableCancelButton
        ? _c(
            "b-button",
            {
              attrs: {
                disabled: _vm.block_ui,
                variant: "danger",
                "data-test": "modal-transaction__btn-cancel",
                block: "",
              },
              on: {
                click: function ($event) {
                  return _vm.$emit("cancelTransaction")
                },
              },
            },
            [_vm._v(" " + _vm._s(_vm.modal.cancel_button) + " ")]
          )
        : _vm._e(),
      _vm.data.status === "PAID" &&
      _vm.data.payment_method_type !== "credit_card" &&
      _vm.findGroups("RULE:TRANSACTIONS:REFUNDED")
        ? _c(
            "b-button",
            {
              attrs: {
                variant: "warning",
                "data-test": "modal-transaction__btn-save",
                block: "",
              },
              on: {
                click: function ($event) {
                  return _vm.$emit("refundPayment")
                },
              },
            },
            [_vm._v(" " + _vm._s(_vm.modal.refundButton) + " ")]
          )
        : _vm._e(),
      _vm.enableButtonWhenNot([
        "PAID",
        "MANUALLY_PAID",
        "CANCELED",
        "SCHEDULED",
        "BALANCE_CREDITED",
        "NESTED_NEGOTIATED",
        "PRE_PAYMENT_RESERVE",
      ]) &&
      _vm.data.id &&
      _vm.data.balance !== 0 &&
      _vm.debitCreditManualConfirmation &&
      _vm.findGroups("RULE:TRANSACTIONS:BALANCE")
        ? _c(
            "b-button",
            {
              directives: [
                {
                  name: "b-tooltip",
                  rawName: "v-b-tooltip.hover",
                  modifiers: { hover: true },
                },
              ],
              attrs: {
                variant: "success",
                title: _vm.$t("payments.buttons.convertToCreditsTitle"),
                block: "",
              },
              on: {
                click: function ($event) {
                  return _vm.$emit("openModalDebitBalance")
                },
              },
            },
            [_vm._v(" " + _vm._s(_vm.$t("payments.buttons.debitCredit")) + " ")]
          )
        : _vm._e(),
      !_vm.enableButtonWhenNot(["PAID"]) &&
      (_vm.isFirstPaymentExchangeAndHasPermission ||
        _vm.isDefaultKindAndHasPermission)
        ? _c(
            "b-button",
            {
              directives: [
                {
                  name: "b-tooltip",
                  rawName: "v-b-tooltip.hover",
                  modifiers: { hover: true },
                },
              ],
              attrs: {
                variant: "success",
                title: _vm.$t("payments.buttons.debitCreditTitle"),
                block: "",
              },
              on: { click: _vm.openConvertToCreditsModal },
            },
            [
              _vm._v(
                " " + _vm._s(_vm.$t("payments.buttons.convertToCredits")) + " "
              ),
            ]
          )
        : _vm._e(),
      !_vm.enableButtonWhenNot(["PAID"]) &&
      (_vm.isPrePaymentAndCreditCardAndHasPermission ||
        _vm.isPrePaymentAndNotCreditCardAndHasPermission ||
        _vm.isPrePaymentReserveAndHasPermission ||
        _vm.isPrePaymentInstallmentAndHasPermission)
        ? _c(
            "b-button",
            {
              directives: [
                {
                  name: "b-tooltip",
                  rawName: "v-b-tooltip.hover",
                  modifiers: { hover: true },
                },
              ],
              attrs: {
                variant: "success",
                title: _vm.$t("payments.buttons.debitCreditTitle"),
                block: "",
              },
              on: { click: _vm.prePaymentInCredits },
            },
            [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("payments.buttons.convertPrePaymentInCredit")) +
                  " "
              ),
            ]
          )
        : _vm._e(),
      !_vm.enableButtonWhenNot(["PAID"]) &&
      ["PRE_PAYMENT_RTO"].includes(_vm.data.kind) &&
      _vm.findGroups("RULE:TRANSACTIONS:CREDIT_PRE_PAYMENT_RTO")
        ? _c(
            "b-button",
            {
              directives: [
                {
                  name: "b-tooltip",
                  rawName: "v-b-tooltip.hover",
                  modifiers: { hover: true },
                },
              ],
              attrs: {
                variant: "success",
                title: _vm.$t("payments.buttons.debitCreditTitle"),
                block: "",
              },
              on: { click: _vm.prePaymentInCredits },
            },
            [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t("payments.buttons.convertPrePaymentRtoInCredit")
                  ) +
                  " "
              ),
            ]
          )
        : _vm._e(),
      !_vm.enableButtonWhenNot(["PENDING", "REFUNDED", "DISPUTED"]) &&
      (_vm.findGroups("RULE:TRANSACTIONS:CREDIT_PRE_PAYMENT") ||
        _vm.findGroups("RULE:TRANSACTIONS:CREDIT_PRE_PAYMENT_RTO"))
        ? _c(
            "b-button",
            {
              directives: [
                {
                  name: "b-tooltip",
                  rawName: "v-b-tooltip.hover",
                  modifiers: { hover: true },
                },
              ],
              attrs: {
                variant: "success",
                title: _vm.$t("payments.buttons.debitCreditTitle"),
                block: "",
              },
              on: { click: _vm.payWithPrePayment },
            },
            [
              _vm._v(
                " " + _vm._s(_vm.$t("payments.buttons.payWithPrePayment")) + " "
              ),
            ]
          )
        : _vm._e(),
      _vm.data.status === "PENDING" &&
      ![
        "PRE_PAYMENT",
        "PRE_PAYMENT_RTO",
        "PRE_PAYMENT_INSTALLMENT",
        "PRE_PAYMENT_PROMOTIONAL",
      ].includes(_vm.data.kind) &&
      _vm.findGroups("RULE:TRANSACTIONS:BONUS")
        ? _c(
            "b-button",
            {
              directives: [
                {
                  name: "b-tooltip",
                  rawName: "v-b-tooltip.hover",
                  modifiers: { hover: true },
                },
              ],
              attrs: {
                variant: "success",
                title: _vm.$t("payments.buttons.markAsBonusTitle"),
                block: "",
              },
              on: {
                click: function ($event) {
                  return _vm.$emit("markAsBonus")
                },
              },
            },
            [_vm._v(" " + _vm._s(_vm.$t("payments.buttons.markAsBonus")) + " ")]
          )
        : _vm._e(),
      _vm.data.status === "PENDING" &&
      _vm.data.holmes_status !== "PENDING" &&
      _vm.data.holmes_status !== "SUCCESS" &&
      _vm.data.gateway === "stark_bank" &&
      _vm.getHourChecked
        ? _c(
            "b-button",
            {
              directives: [
                {
                  name: "b-tooltip",
                  rawName: "v-b-tooltip.hover",
                  modifiers: { hover: true },
                },
              ],
              attrs: {
                variant: "warning",
                title: _vm.$t("payments.buttons.checkPaymentAttemptTitle"),
                block: "",
              },
              on: {
                click: function ($event) {
                  return _vm.$emit("checkPaymentAttempt")
                },
              },
            },
            [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("payments.buttons.checkPaymentAttempt")) +
                  " "
              ),
            ]
          )
        : _vm._e(),
      _vm.isContestationButtonsAllowed &&
      !_vm.isContestationOpen &&
      ["PENDING", "PAID"].includes(_vm.data.status)
        ? _c(
            "b-button",
            {
              attrs: { variant: "warning", block: "" },
              on: {
                click: function ($event) {
                  return _vm.$emit("openModalOpenContestation")
                },
              },
            },
            [
              _vm._v(
                " " + _vm._s(_vm.$t("payments.buttons.openContestation")) + " "
              ),
            ]
          )
        : _vm._e(),
      _vm.isContestationButtonsAllowed && _vm.isContestationOpen
        ? _c(
            "b-button",
            {
              attrs: { variant: "warning", block: "" },
              on: {
                click: function ($event) {
                  return _vm.$emit("openModalCloseContestation")
                },
              },
            },
            [
              _vm._v(
                " " + _vm._s(_vm.$t("payments.buttons.closeContestation")) + " "
              ),
            ]
          )
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "w-100",
          staticStyle: { position: "absolute", bottom: "0", right: "0" },
        },
        [
          _c(
            "b-button",
            {
              staticClass: "relative",
              attrs: { "data-test": "modal-transaction__btn-close", block: "" },
              on: {
                click: function ($event) {
                  return _vm.$emit("close")
                },
              },
            },
            [_vm._v(" " + _vm._s(_vm.modal.close_button) + " ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }